import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { toggleMenu } from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import ProductReducer from "./reducers/ProductsReducer";
import CategoryReducer from "./reducers/CategoryReducer";

//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  sideMenu: toggleMenu,
  auth: AuthReducer,
  products: ProductReducer,
  category: CategoryReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
